import { Stack, Typography } from '@mui/material';
import {
  challengesComparator,
  GameCourseLevel,
  GameEvent,
  GameEventType,
  GolfCourseConfig,
  ShotContentChallengeState,
} from 'common/infrastructure/modules/game';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { animated, useTransition } from '@react-spring/web';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { ChallengeRowComponent } from 'targets/web/modules/display/components';

import { useGolfCourseContext } from 'targets/web/modules/display/hooks/useGolfCourseContext';
import { spacing } from 'targets/web/theme/spacing';
import { useResizeObserver } from 'usehooks-ts';

const getChallengesFromConfig = (gameConfig: GolfCourseConfig, gameLevel: GameCourseLevel) =>
  gameConfig.gameLevels.find((x) => x.gameLevel === gameLevel)?.challenges ?? [];

export const getChallengesToDisplay = (
  gameConfig: GolfCourseConfig,
  holeNum: number,
  event: GameEvent,
) =>
  getChallengesFromConfig(gameConfig, event.gameCourseLevel)
    .filter((x) => x.holeNum === holeNum)
    .map((x) => {
      const eventData =
        event.type === GameEventType.SHOT
          ? event.content.find((challenge) => challenge.id === x.id)
          : null;

      return {
        ...x,
        state: eventData?.state ?? ShotContentChallengeState.IN_PROGRESS,
      };
    })
    .sort(challengesComparator);

const LIST_GAP = spacing(4);

export const ContestInProgress: FC = () => {
  const t = useTranslationPrefix('contestInProgress');
  const { lastGameEvent, gameConfigs } = useGolfCourseContext();
  const gameConfig = useMemo(
    () => gameConfigs.find((x) => x.displayName === 'Pebble Beach 9 Hole') ?? gameConfigs[0],
    [gameConfigs],
  );

  const [currentHole, setCurrentHole] = useState(lastGameEvent?.hole ?? 1);
  const challengesListRef = useRef<HTMLDivElement>(null);
  const { height = window.innerHeight * 0.6 } = useResizeObserver({
    ref: challengesListRef,
    box: 'border-box',
  });

  const title = useMemo(
    () =>
      [
        t('holeHeader', { currentHole, totalHoles: gameConfig?.numHoles ?? 0 }),
        t(`courseNames.${gameConfig?.displayName ?? 'Peble Beach 9 Hole'}`),
      ].join(' - '),
    [currentHole, gameConfig?.numHoles, gameConfig?.displayName, t],
  );

  const challenges = useMemo(
    () =>
      gameConfig && lastGameEvent
        ? getChallengesToDisplay(gameConfig, currentHole, lastGameEvent)
        : [],
    [currentHole, gameConfig, lastGameEvent],
  );

  const itemHeight = useMemo(
    () => (height - LIST_GAP * (challenges.length - 1)) / (challenges.length || 1),
    [height, challenges.length],
  );

  const transitions = useTransition(challenges, {
    key: (item: typeof challenges[number]) => item.id,

    from: { y: 0, opacity: 1, height: itemHeight },
    leave: { opacity: 0 },
    enter: (_, index) => ({
      y: (itemHeight + LIST_GAP) * index,
    }),
    update: (_, index) => ({
      zIndex: 100 - index,
      y: (itemHeight + LIST_GAP) * index,
      height: itemHeight,
    }),

    exitBeforeEnter: true,
  });

  useEffect(() => {
    if (!gameConfig || !lastGameEvent) {
      return;
    }

    if (lastGameEvent.type === GameEventType.HOLE_CHANGED) {
      setCurrentHole(lastGameEvent.hole);

      return;
    }
  }, [lastGameEvent]);

  return (
    <Stack alignItems="center" height={1} gap={6}>
      <Stack sx={{ textAlign: 'center', textTransform: 'uppercase', color: 'typo.white.primary' }}>
        <Typography variant="headlineLarge">{title}</Typography>

        <Typography variant="headlineLarge">
          {t('subTitle', {
            numberOfCompletedChallenges: lastGameEvent?.players[0].challengesWon ?? 0,
          })}
        </Typography>
      </Stack>

      <Stack
        ref={challengesListRef}
        gap={4}
        width={1}
        flex={1}
        overflow="hidden"
        position="relative">
        {transitions((style, challenge) => (
          <animated.div
            style={{
              ...style,
              position: 'absolute',
              width: '100%',
              display: 'flex',
            }}>
            <ChallengeRowComponent key={challenge.id} {...challenge} />
          </animated.div>
        ))}
      </Stack>
    </Stack>
  );
};
