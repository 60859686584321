import { CircularProgress, Typography } from '@mui/material';
import { useGetLastGameEvent } from 'common-frontend';
import { useCallback, useEffect } from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { ContentContainer, Header, PageLayout } from 'targets/web/modules/common/components';

import { useDispatchDisplayRoutes } from '../../hooks/useDispatchDisplayRoutes';
import { DisplayNavigationRoutes } from '../../navigation/DisplayNavigationRoutes';
import { TestButtonsSection } from './TestButtonsSection';

const LoadingScreen = () => {
  const t = useTranslationPrefix('common');

  return (
    <ContentContainer
      sx={{ color: 'typo.white.primary', alignItems: 'center', justifyContent: 'center', gap: 6 }}>
      <CircularProgress size={128} />

      <Typography variant="displayLarge">{t('loading')}</Typography>
    </ContentContainer>
  );
};

export const DisplayPageLayout = () => {
  const { locationId, bayId } = useParams();
  const location = useLocation();

  const { lastGameEvent, initialLoad } = useGetLastGameEvent({
    location: locationId,
    bay: bayId,
    enabled: !!bayId,
  });

  // TODO: Put back for meta games
  // const { data: gameConfigs } = useCaseGetGolfCoursesConfig(locationId);

  useDispatchDisplayRoutes(lastGameEvent);

  const toggleFullScreen = useCallback(() => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }, []);

  useEffect(() => {
    const fullscreenHandler = (event: KeyboardEvent) => {
      if (event.key === 'f') {
        toggleFullScreen();
      }
    };

    document.addEventListener('keydown', fullscreenHandler);

    return () => {
      document.removeEventListener('keydown', fullscreenHandler);
    };
  }, [toggleFullScreen]);

  const title = lastGameEvent
    ? `${lastGameEvent.location} - ${lastGameEvent.bay}`
    : `${locationId} - ${bayId}`;
  const hasCustomLayout =
    location.pathname.includes(DisplayNavigationRoutes.WaitingRoom) ||
    location.pathname.includes(DisplayNavigationRoutes.Results);
  const shouldShowLoader = !lastGameEvent && initialLoad;

  const parts = location.pathname.split('/').filter(Boolean);
  if (!locationId || !bayId || parts.length < 3) {
    return <Navigate to="/404" />;
  }

  if (hasCustomLayout) {
    return shouldShowLoader ? (
      <PageLayout>
        <LoadingScreen />
      </PageLayout>
    ) : (
      <>
        <Outlet context={{ lastGameEvent, title }} />
        <TestButtonsSection locationId={locationId} bayId={bayId} />
      </>
    );
  }

  return (
    <PageLayout>
      {shouldShowLoader ? (
        <LoadingScreen />
      ) : (
        <>
          <Header title={title} user={lastGameEvent?.players[0].name} />
          <ContentContainer overflow={'hidden'}>
            <Outlet context={{ lastGameEvent, gameConfigs: [] }} />
          </ContentContainer>
        </>
      )}

      <TestButtonsSection locationId={locationId} bayId={bayId} />
    </PageLayout>
  );
};
